

export const slides6 = [
  {
    id: 1,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/bin-shaikh.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/bin-shaikh.png",
    title: "Bin Shaikh",
  },
  {
    id: 2,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/ignite-oud.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/ignite-oud.png",
    title: "Ignite oud",
  },
  {
    id: 3,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/bin-shaikh.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/bin-shaikh.png",
    title: "Bin Shaikh",
  },
  {
    id: 4,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/ignite-oud.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/ignite-oud.png",
    title: "Ignite oud",
  },
];

export const slidesData3 = [
  // {
  //   id: 1,
  //   imageSrc: "/assets/images/banner/uae-sale.jpg",
  //   season: "",
  //   objectPosition: "50% center",
  //   title: "",
  //   subtitle: "",
  //   href: "shop/",
  //   color: "text-black"
  // },
  {
    id: 2,
    imageSrc: "/assets/images/la-rose.jpg",
    season: "La Rosee",
    objectPosition: "50% center",
    title: "Feel Luxurious",
    subtitle: "with",
    href: "shop/perfumes/occidental-fragrance/la-rosee",
    color: "text-black"
  },
  {
    id: 3,
    imageSrc: "/assets/images/banner/AHL-Banner.jpg",
    season: "AHL",
    objectPosition: "70% center",
    title: "The Essence",
    subtitle: "of Belonging",
    href: "shop/perfumes/oriental-fragrance/ahl",
    color: "text-black"
  },
  
];
export const slidesData33 = [
  // {
  //   id: 1,
  //   imageSrc: "/assets/images/banner/uae-mobile.jpg",
  //   season: "",
  //   objectPosition: "50% center",
  //   title: "",
  //   subtitle: "",
  //   href: "shop/",
  //   color: "text-black"
  // },
  {
    id: 2,
    imageSrc: "/assets/images/la-rose.jpg",
    season: "La Rosee",
    objectPosition: "50% center",
    title: "Feel Luxurious",
    subtitle: "with",
    href: "shop/perfumes/occidental-fragrance/la-rosee",
    color: "text-black"
  },
  {
    id: 3,
    imageSrc: "/assets/images/banner/AHL-Banner.jpg",
    season: "AHL",
    objectPosition: "70% center",
    title: "The Essence",
    subtitle: "of Belonging",
    href: "shop/perfumes/oriental-fragrance/ahl",
    color: "text-black"
  },

];



export const slideData1000 = [
  // {
  //   id: 0,
  //   bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
  //   title: "Natural Glow",
  //   description:
  //     "Eid Mubarak! 🌙✨ Wishing You Joy, Peace & Blessings!",
  // },
  {
    id: 1,
    bgImage: "/assets/images/home/demo15/slider1_bg.jpg",
    title: "Natural Glow",
    description:
      "Free Shipping on orders AED 400 and above",
  },
  {
    id: 2,
    bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
    title: "Natural Glow",
    description:
      "A huge selection of best fragrance",
  },
  // {
  //   id: 3,
  //   bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
  //   title: "Natural Glow",
  //   description:
  //     "FOR ANY INQUIRIES, PLEASE CONTACT OUR CUSTOMER CARE TEAM AT +800 472 1828",
  // },
 
];


  // Add more slide objects as needed

